<template>
  <b-modal
    id="prompt-modal"
    :title="title ? title : $t('modal.confirm.title')"
    header-bg-variant="red"
    header-text-variant="white"
    body-bg-variant="white"
    body-text-variant="dark"
    footer-bg-variant="white"
    footer-text-variant="red"
  >
    <b-container fluid>
      <b-row>
        <b-col
          lg="12"
          sm="12"
        >
          {{ message ? message : $t('modal.confirm.message') }}
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-if="!yesOnly"
          size="md"
          @click="no"
        >
          {{ $t("no") }}
        </b-button>
        <b-button
          variant="outline-red"
          size="md"
          class="float-right"
          @click="yes"
        >
          {{ yesText ? yesText : $t("yes") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "PromptModal",

  props: {
    title: {
      required: false,
      type: String,
    },

    message: {
      required: false,
      type: String,
    },
    yesOnly: Boolean,
    yesText: String,
  },

  data () {
    return {
    };
  },

  mounted () {},

  methods: {
    no () {
      this.$emit("no");
      this.$bvModal.hide("prompt-modal");
    },

    yes () {
      this.$emit("yes");
      this.$bvModal.hide("prompt-modal");
    },
  },
};
</script>
