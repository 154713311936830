<template>
  <b-container
    v-if="decks && decks.length > 0 && selectedTemplate"
    class="mb-5"
  >
    <b-col
      lg="10"
      sm="12"
      xs="12"
      md="10"
      offset-lg="1"
      offset-md="1"
    >
      <b-card
        class="hubu-bg-red"
        :header="appText('my_cards_title')"
        header-tag="header"
      >
        <b-row>
          <b-col lg="9">
            <h3>{{ $t("my_templates") }}</h3>
            <p>{{ $t("my_templates_description") }}</p>
          </b-col>
          <b-col>
            <router-link
              v-if="authorizations.templateCreation"
              :to="{ name: 'CreateTemplate' }"
              tag="b-button"
            >
              {{ $t("create_template") }}
            </router-link>
            <b-button
              v-else
              :disabled="ongoingRequest"
              class="m-3"
              @click="openPromptModal"
            >
              {{ $t("create_template") }}
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            lg="12"
            sm="12"
            class="text-center"
          >
            <template v-if="decks">
              <b-button
                v-for="(deck, index) in decks"
                ref="levelBtns"
                :key="index"
                :disabled="ongoingRequest"
                data-selected="false"
                :data-key="JSON.stringify(deck)"
                class="m-3"
                :style="
                  'border-color: ' +
                    deck.bg_color +
                    '; background-color: #fff; color: ' +
                    deck.bg_color +
                    ';'
                "
                @click="selectDeck"
              >
                {{ deck.subtitle }}
              </b-button>
            </template>
          </b-col>
        </b-row>

        <b-row v-if="selected != null">
          <b-col>
            <b-select
              v-model="selectedTemplate"
              :disabled="ongoingRequest"
              :options="templates"
              @change="templateChanged"
            ></b-select>
          </b-col>
        </b-row>

        <template v-if="selected != null">
          <b-row>
            <b-col lg="12">
              <b-form-group
                id="fieldset-template-name"
                :description="$t('template_name')"
                :label="$t('template_name')"
                label-for="template-name"
              >
                <b-form-input
                  id="template-name"
                  v-model="templateName"
                  :disabled="ongoingRequest"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="12">
              <b-form-group
                id="fieldset-template-description"
                :description="$t('template_description')"
                :label="$t('template_description')"
                label-for="template-description"
              >
                <b-form-textarea
                  id="template-description"
                  v-model="templateDescription"
                  :disabled="ongoingRequest"
                  rows="8"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              lg="12"
              sm="12"
            >
              <b-form-checkbox
                v-model="isPublic"
                :disabled="ongoingRequest || isBaseTemplate || !authorizations.templateEdit"
              >
                Public
              </b-form-checkbox>
              {{ $t("template_public_private") }}
            </b-col>
          </b-row>

          <b-row
            v-if="selected"
            class="mt-5"
          >
            <b-col
              lg="12"
              sm="12"
            >
              <b-row>
                <b-col
                  lg="2"
                  sm="2"
                >
                  <b-form-checkbox
                    :disabled="isBaseTemplate || ongoingRequest || !authorizations.templateEdit"
                    @change="selectAll"
                  >
                    {{ $t("select_all") }}
                  </b-form-checkbox>
                </b-col>

                <b-col
                  lg="5"
                  sm="5"
                >
                  Action
                </b-col>
                <b-col
                  lg="5"
                  sm="5"
                >
                  Question
                </b-col>
              </b-row>

              <hr />

              <cards-list
                v-if="renderComponent"
                :template-cards="cards"
                :deck="deckData"
                :is-base-template="isBaseTemplate || ongoingRequest"
                :on-selected-change="handleSelectedValueChanged"
                :editable="authorizations.templateEdit"
              ></cards-list>
            </b-col>
          </b-row>
        </template>

        <b-row
          v-if="changedAndSelected !== null"
          class="mt-5"
        >
          <b-col
            lg="12"
            class="text-center"
          >
            <b-spinner
              v-if="ongoingRequest"
              label="Loading..."
            ></b-spinner>
            <template v-else>
              <b-button
                v-if="!isBaseTemplate"
                size="md"
                class=""
                :disabled="ongoingRequest"
                @click="deleteTemplate"
              >
                {{
                  $t("delete")
                }}
              </b-button>
              <b-button
                v-if="isBaseTemplate ? (customTemplateName !== null || customTemplateDescription !== null || customIsPublic !== null) : (changedAndSelected !== null || customIsPublic !== null || customTemplateName !== null || customTemplateDescription !== null)"
                size="md ml-2"
                class="btn-red"
                :disabled="ongoingRequest"
                @click="save"
              >
                {{
                  $t("save")
                }}
              </b-button>
            </template>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <prompt-modal
      :title="getText(`admin_only_title${isSilver ? '_silver' : ''}`)"
      :message="getText(`admin_only_subtitle${isSilver ? '_silver' : ''}`)"
      :yes-only="true"
      :yes-text="isSilver ? getText('admin_only_button_silver') : 'Ok'"
      @yes="() => closeModal()"
    />
  </b-container>
</template>

<script>
import CardsList from "./CardsList.vue";
import { mapGetters } from "vuex";
import PromptModal from "./PromptModal";

const defaultTemplate = {
  description: "Une partie qui comprend à la fois les cartes feedback et les cartes clin d’œil. Les cartes clin d’œil amènent les joueurs à parler d’eux-mêmes. Les cartes feedback amènent amènent les joueurs à donner ou à recevoir du feedback. \n**Pour qui:**\n- Pour les équipes qui veulent apprendre à mieux se connaître\n- Pour accueillir de nouveaux membres dans l’équipe (utilisez le bouton clin d'œil sous le paquet)\n- Comme activité brise-glace avant une réunion\n- Pour les équipes qui se connaissent bien\n- Pour célébrer les bons coups\n- Pour se donner du feedback positif et constructif",
  id: 1,
  title:"Standard",
};

export default {
  name: "ChooseCards",

  components: {
    PromptModal,
    CardsList,
  },

  data () {
    return {
      adminOnlyModal: true,
      renderComponent: true,
      deckData: null,
      Decks: null,
      deckCards: [],
      selected: null,
      langs: [this.$i18n.locale],
      selectedTemplate: null,
      templateDescriptions: {
        Standard:
          "Une partie qui comprend à la fois les cartes feedback et les cartes clin d’œil. Les cartes clin d’œil amènent les joueurs à parler d’eux-mêmes. Les cartes feedback amènent amènent les joueurs à donner ou à recevoir du feedback.",
        Wink:
          "Une partie qui comprend uniquement les cartes clin d’œil. Ces cartes amènent les joueurs à parler d’eux-mêmes. Parfait pour les équipes qui veulent apprendre à se connaître dans un contexte de télétravail.",
        Feedback:
          "Une partie qui comprend uniquement les cartes feedback. Ces cartes amènent les joueurs à donner ou à recevoir du feedback. Parfait comme activité de reconnaissance ou pour avoir des conversations sincères et significatives.",
      },
      templatesData: {},
      customIsPublic: null,
      customTemplateName: null,
      customTemplateDescription: null,
      templateOptions: [], //["Standard", "Wink", "Feedback"],
      standardTemplates: new Object(),
      originalStandardTemplates: [],
      changedAndSelected: [],
      ongoingRequest: false,
    };
  },
  computed: {
    ...mapGetters(["authorizations", "getTemplates", "decks", "getUserData", "getDeck", "getLang"]),
    isSilver () {
      return this.$store.getters.getOrganization?.subscription === "Silver";
    },
    isPublic: {
      get () {
        return this.selectedTemplate.private ? this.selectedTemplate.private : this.customIsPublic === null ? true : this.customIsPublic;
      },
      set (checked) {
        if(this.selectedTemplate.hasOwnProperty("private")) {
          this.selectedTemplate.private = !checked;
        } else {
          this.customIsPublic = checked;
        }
      },
    },
    isBaseTemplate () {
      return this.selectedTemplate.title == "Clin d'œil" || this.selectedTemplate.title == "Standard" || this.selectedTemplate.title == "Feedback" || this.selectedTemplate.title == "Wink";
    },
    templates (){
      return [...this.originalStandardTemplates.map(val => ({ value: val, text: val.title, id: val.id })), ...(this.selectedDeck.id ? this.getDeck(this.selectedDeck.id).templates.map(template => ({ value: template, text: template.name, id: template.id })).filter(template => template.value.private ? (template.value.hat && template.value.hat == this.getUserData.hats[0].id) : true): [])];
    },
    cards () {
      const baseCards = this.isBaseTemplate ? this.$store.getters.getBaseTemplateCards(this.selectedTemplate.title, this.deckData) : this.selectedTemplate.cards;

      return baseCards;
    },
    selectedDeck () {
      return this.decks.find(deck => deck.id == this.selected) || {};
    },
    templateName: {
      get () {
        return this.customTemplateName || this.selectedTemplate.title || this.selectedTemplate.name;
      },
      set (name) {
        this.customTemplateName = name;
      },
    },
    templateDescription: {
      get () {
        return this.customTemplateDescription || this.selectedTemplate.description;
      },
      set (name) {
        this.customTemplateDescription = name;
      },
    },
  },

  watch: {
    async "$i18n.locale" () {
      this.selectedTemplate = null;
      this.selected = null;

      !this.$store.getters.organizations && await this.$store.dispatch("getOrganizations");
      await this.$store
        .dispatch("getDecks")
        .then(() => {
          this.$store.dispatch("getStandardTemplates").then(() => {
            var templateDescriptions = this.$store.getters.standardTemplates;
            templateDescriptions = [templateDescriptions.deck.find(deck => deck.title === "Standard")];

            this.originalStandardTemplates = templateDescriptions;
            var tmpDesc = new Object();
            this.selectedTemplate = templateDescriptions.find(template => template.title == "Standard");

            templateDescriptions.forEach((val) => {
              tmpDesc[val.title] = val;
            });

            this.standardTemplates = tmpDesc;

            this.$forceUpdate();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLang () {
      this.$store.dispatch("getStandardTemplates").then(() => {
        var templateDescriptions = this.$store.getters.standardTemplates;
        templateDescriptions = [templateDescriptions.deck.find(deck => deck.title === "Standard")];

        this.originalStandardTemplates = templateDescriptions;
        var tmpDesc = new Object();
        this.selectedTemplate = templateDescriptions.find(template => template.title == "Standard");

        templateDescriptions.forEach((val) => {
          tmpDesc[val.title] = val;
        });

        this.standardTemplates = tmpDesc;

        this.$forceUpdate();
      });
    },
  },

  async mounted () {
    !this.$store.getters.organizations && await this.$store.dispatch("getOrganizations");
    await this.$store
      .dispatch("getDecks")
      .then(() => {
        this.$store.dispatch("getStandardTemplates").then(() => {
          var templateDescriptions = this.$store.getters.standardTemplates;
          templateDescriptions = [templateDescriptions.deck.find(deck => deck.title === "Standard")];

          this.originalStandardTemplates = templateDescriptions;
          var tmpDesc = new Object();
          this.selectedTemplate = templateDescriptions.find(template => template.title == "Standard");

          templateDescriptions.forEach((val) => {
            tmpDesc[val.title] = val;
          });

          this.standardTemplates = tmpDesc;

          this.$forceUpdate();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    closeModal () {
      this.isSilver && (window.location = this.getText("admin_only_url_silver"));
      this.adminOnlyModal = false;
    },
    getText (key) {
      return this.$store.getters.getAppTexts[key]?.content ?? "";
    },
    openPromptModal () {
      this.$bvModal.show("prompt-modal");
    },
    templateChanged (template) {
      this.selectedTemplate = template;
      this.changedAndSelected = [];
      this.customTemplateName = null;
      this.customTemplateDescription = null;
    },

    handleSelectedValueChanged (selectedCards) {
      this.changedAndSelected = selectedCards;
    },

    forceRerender () {
      // Removing my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },

    save () {
      const name = this.customTemplateName || this.selectedTemplate.title || this.selectedTemplate.name;
      if (this.deckData == null || !name || name?.length < 4) {
        this.$toast.open({
          message: "Please make sure to select a level and fill the name",
          type: "error",
        });
        return false;
      }

      const cards = this.selectedTemplate.cards.map(card => ({ ...card, enabled: this.changedAndSelected.find(id => id == card.id) ? true : false }));

      this.langs.forEach((lang) => {
        var data = {
          name,
          description: this.customTemplateDescription || this.selectedTemplate.description,
          bg_color: this.deckData.bg_color,
          text_color: this.deckData.text_color,
          subtitle: "Custom deck",
          private: this.selectedTemplate.hasOwnProperty("private") ? this.selectedTemplate.private : !this.customIsPublic,
          cards:cards,
          locale: lang,
          deck: this.selected,
          id: this.selectedTemplate.id,
        };
        this.ongoingRequest = true;
        this.$store
          .dispatch("updateTemplate", data)
          .then(async () => {
            await this.$store.dispatch("getDecks");
            this.selectedTemplate = this.selectedDeck.templates.find(template => template.id == this.selectedTemplate.id);

            this.ongoingRequest = false;
            
            this.$toast.open(this.$t("template_updated"), {
              // override the global option
              position: "bottom",
            });
          })
          .catch(() => {
            this.$toast.open({ message: "Unexpected error", type: "error" });
          });
      });
    },

    selectAll (selected) {
      this.templateChanged({ ...this.selectedTemplate, cards: this.cards.map(card => ({ ...card, enabled: selected })) });
    },

    promptTemplateDelete () {
      this.$bvModal.show("prompt-modal");
    },

    deleteTemplate () {
      this.ongoingRequest = true;
      this.$store
        .dispatch("deleteTemplate", this.selectedTemplate.id)
        .then(async () => {
          this.ongoingRequest = false;
          this.selectedTemplate = defaultTemplate;
          await this.$store.dispatch("getDecks");

          this.$toast.open(this.$t("template_deleted"), {
            // override the global option
            position: "bottom",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.open({
            message: err,
            type: "error",
          });
        });
    },

    selectDeck (e) {
      var btns = this.$refs.levelBtns;
      var data = JSON.parse(e.target.getAttribute("data-key"));
      var selected = e.target.getAttribute("data-selected");

      btns.forEach((btn) => {
        var btnData = JSON.parse(btn.getAttribute("data-key"));
        btn.setAttribute("data-selected", "false");
        btn.style.backgroundColor = "#fff";
        btn.style.color = btnData.bg_color;
      });

      if (selected == "false") {
        e.target.setAttribute("data-selected", "true");
        e.target.style.backgroundColor = data.bg_color;
        e.target.style.color = data.text_color;
        this.selected = data.id;
        this.deckData = data;
        this.currentDeck = this.decks.filter((val) => {
          if (val.id == data.id) {
            return true;
          }
          return false;
        });
        this.currentDeck = this.currentDeck[0];
      } else {
        e.target.setAttribute("data-selected", "false");
        e.target.style.backgroundColor = "#fff";
        e.target.style.color = data.bg_color;
        this.selected = null;
      }

      this.templateOptions = [];

      this.originalStandardTemplates.forEach((val) => {
        this.templateOptions.push({ value: val.title, text: val.title, id: val.id });
      });

   

      this.forceRerender();
      this.$forceUpdate();
    },

    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object"
        ? this.$store.getters.getAppTexts[text].content
        : text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}
</style>

