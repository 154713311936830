<template>
  <b-container>
    <b-row
      v-for="(card, index) in templateCards"
      :key="index"
      class="mt-1"
    >
      <b-col
        lg="2"
        sm="2"
      >
        <b-form-checkbox
          ref="cardSelector"
          :key="card.id"
          v-model="selectedCards"
          :disabled="isBaseTemplate || !editable"
          :data-card-title="card.action"
          :data-card-subtitle="card.question"
          :data-card-winks="card.winks"
          :data-card-number="card.number"
          :value="card.id"
        ></b-form-checkbox>
      </b-col>
      <b-col
        lg="5"
        sm="5"
      >
        {{ card.action }}
      </b-col>
      <b-col
        lg="5"
        sm="5"
      >
        {{ card.question }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "CardsList",
  props: {
    deck: {
      type: Object,
    },
    templateCards: {
      type: Array,
    },
    onSelectedChange: Function,
    selectedAll: Boolean,
    isBaseTemplate: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
  },

  data () {
    return {
      templates: null,
      selectedCards: [],
    };
  },
  watch: {
    templateCards () {
      this.setSelectedCards();
    },
    selectedCards () {
      this.onSelectedChange(this.selectedCards);
    },
    selectedAll (selected) {
      this.selectedCards = selected ?  this.deck.cards.map(card => card.id) : [];
    },
  },
  mounted () {
    this.setSelectedCards();
  },
  methods: {
    setSelectedCards () {
      this.selectedCards = this.templateCards.reduce((cardIds, card) => (card.enabled ? [...cardIds, card.id] : cardIds), []);
    },
  },
};
</script>
